// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.czMReiuKlXN98srHjQkf{font-size:18px;font-weight:600;overflow:hidden;white-space:nowrap;text-overflow:ellipsis}.OwfOMNyeOmPSb4Bd9W1A{padding:24px 24px 10px 24px;position:relative}.vL0j36AYAaa5tG0SVUdQ{position:absolute;top:5px;left:5px;height:17px;width:17px}.M2nHHewdzF9_UhVFudax{font-size:12px;font-weight:normal}`, "",{"version":3,"sources":["webpack://./src/pages/Assets/pages/WorkList/components/WorkCard/component/Header/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,eAAA,CACA,eAAA,CACA,kBAAA,CACA,sBAAA,CACF,sBACE,2BAAA,CACA,iBAAA,CACF,sBACE,iBAAA,CACA,OAAA,CACA,QAAA,CACA,WAAA,CACA,UAAA,CACF,sBACE,cAAA,CACA,kBAAA","sourcesContent":[".title\n  font-size: 18px\n  font-weight: 600\n  overflow: hidden\n  white-space: nowrap\n  text-overflow: ellipsis\n.header\n  padding: 24px 24px 10px 24px\n  position: relative\n.checkbox\n  position: absolute\n  top: 5px\n  left: 5px\n  height: 17px\n  width: 17px\n.publisher\n  font-size: 12px\n  font-weight: normal\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `czMReiuKlXN98srHjQkf`,
	"header": `OwfOMNyeOmPSb4Bd9W1A`,
	"checkbox": `vL0j36AYAaa5tG0SVUdQ`,
	"publisher": `M2nHHewdzF9_UhVFudax`
};
export default ___CSS_LOADER_EXPORT___;
