// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Iuali9PlqDfW6usvhKXg{color:#f13636 !important}.NzZYUm0wH0SAbXuL4GjZ{width:120px;height:30px}.w5WPtT9RvgsH9nPJqw1S{width:120px}.S1_bYgBh_QJ89ZjHcu3b th{color:#f13636 !important}.O4I5VF82TSfXQiLVw4lg{display:flex;gap:10px;align-items:center}`, "",{"version":3,"sources":["webpack://./src/pages/AssetsBatches/pages/AssetsBatch/components/Validations/components/NeedValidationTable/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CACF,sBACE,WAAA,CACA,WAAA,CACF,sBACE,WAAA,CAEA,yBACE,wBAAA,CACJ,sBACE,YAAA,CACA,QAAA,CACA,kBAAA","sourcesContent":[".fontColor\n  color: #f13636 !important\n.select\n  width: 120px\n  height: 30px\n.input\n  width: 120px\n.tableHeader\n  th\n    color: #f13636 !important\n.title\n  display: flex\n  gap: 10px\n  align-items: center\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fontColor": `Iuali9PlqDfW6usvhKXg`,
	"select": `NzZYUm0wH0SAbXuL4GjZ`,
	"input": `w5WPtT9RvgsH9nPJqw1S`,
	"tableHeader": `S1_bYgBh_QJ89ZjHcu3b`,
	"title": `O4I5VF82TSfXQiLVw4lg`
};
export default ___CSS_LOADER_EXPORT___;
