// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xwcy6qlHDpPXv9XCbRov{width:100%}.PReEPH4fPxG8GfLht5Os{width:100%;display:flex;margin-top:8px}.sI84aJ69tBjcd1EMXNfv{width:7%;heigth:10px;margin-top:7px}.ZhNB3wxTyw1NelhX7x5G{width:30%}@media(max-width: 1048px){.ZhNB3wxTyw1NelhX7x5G{width:40%}}.vsNUMrt6m_BUCWSMuRjT{width:25%}`, "",{"version":3,"sources":["webpack://./src/pages/SingleAssets/pages/assets-epub-txt/components/RevenueBreakdown/components/SharesChart/styles.module.sass","webpack://./src/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,UAAA,CACF,sBACE,UAAA,CACA,YAAA,CACA,cAAA,CACF,sBACE,QAAA,CACA,WAAA,CACA,cAAA,CACF,sBACE,SAAA,CCEA,0BDHF,sBAGI,SAAA,CAAA,CACJ,sBACE,SAAA","sourcesContent":["@import \"@/assets/sass/base/breakpoints\"\n\n.chartContainer\n  width: 100%      \n.labelsContainer\n  width: 100% \n  display: flex \n  margin-top: 8px\n.iconContainer \n  width: 7%\n  heigth: 10px\n  margin-top: 7px\n.labelContainer\n  width: 30%\n  @include small-pc\n    width: 40%\n.percentageContainer\n  width: 25%","$screen-xs: 440px\n$screen-sm: 576px\n$screen-md: 768px\n$screen-lg: 992px\n$screen-xl: 1200px\n\n@mixin small-mobile\n   @media (max-width: #{$screen-xs})\n    @content\n\n@mixin mobile\n  @media (max-width: #{$screen-md})\n    @content\n\n@mixin small-pc\n  @media (max-width: #{1048px})\n    @content\n\n@mixin sm   \n  @media (min-width: #{$screen-sm})\n    @content\n\n@mixin md\n   @media (min-width: #{$screen-md})\n    @content\n\n@mixin lg\n  @media (min-width: #{$screen-lg})\n    @content\n\n@mixin xl\n  @media (min-width: #{$screen-xl})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chartContainer": `xwcy6qlHDpPXv9XCbRov`,
	"labelsContainer": `PReEPH4fPxG8GfLht5Os`,
	"iconContainer": `sI84aJ69tBjcd1EMXNfv`,
	"labelContainer": `ZhNB3wxTyw1NelhX7x5G`,
	"percentageContainer": `vsNUMrt6m_BUCWSMuRjT`
};
export default ___CSS_LOADER_EXPORT___;
