// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.x5HQG9Vfz4fE6fPDBLT8{width:150px}.x5HQG9Vfz4fE6fPDBLT8.rG3xUvFAUEoPGNuZ6KST{width:220px}.C9XcPGWJSjqFcURiximF{width:100%}.Ap3YzxtuCeVbIqtpHnBA{display:flex;gap:10px}`, "",{"version":3,"sources":["webpack://./src/pages/Assets/pages/ListMultipleWorks/component/FormSection/components/StandardLicense/component/InputTextFields/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,2CACE,WAAA,CACJ,sBACE,UAAA,CACF,sBACE,YAAA,CACA,QAAA","sourcesContent":[".field\n  width: 150px\n  &.big\n    width: 220px\n.input\n  width: 100%\n.row\n  display: flex\n  gap: 10px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field": `x5HQG9Vfz4fE6fPDBLT8`,
	"big": `rG3xUvFAUEoPGNuZ6KST`,
	"input": `C9XcPGWJSjqFcURiximF`,
	"row": `Ap3YzxtuCeVbIqtpHnBA`
};
export default ___CSS_LOADER_EXPORT___;
