// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.T19fmO8kj24085yCY3oA{font-weight:bold;color:var(--color-main);margin-bottom:.5rem}.k9OJpl1saOR4YkBHNXKH{overflow-y:scroll}`, "",{"version":3,"sources":["webpack://./src/pages/Assets/pages/ListMultipleWorks/component/FormSection/components/StandardLicense/component/LLMAvailability/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CACA,uBAAA,CACA,mBAAA,CACF,sBACE,iBAAA","sourcesContent":[".title\n  font-weight: bold\n  color: var(--color-main)\n  margin-bottom: .5rem\n.content\n  overflow-y: scroll"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `T19fmO8kj24085yCY3oA`,
	"content": `k9OJpl1saOR4YkBHNXKH`
};
export default ___CSS_LOADER_EXPORT___;
