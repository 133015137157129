// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.maPUWzpZDzxdUMZJvz2e{position:relative}`, "",{"version":3,"sources":["webpack://./src/pages/ListMultipleAssets/page/RegisterListingBatch/components/SelectAssets/components/AssetCard/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,iBAAA","sourcesContent":[".card\n  position: relative\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `maPUWzpZDzxdUMZJvz2e`
};
export default ___CSS_LOADER_EXPORT___;
