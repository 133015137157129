// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.l1z11DE27A_Gs6a6kXLw{width:100%;max-width:700px}`, "",{"version":3,"sources":["webpack://./src/pages/AssetsBatches/pages/AssetsBatch/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,eAAA","sourcesContent":[".defaultWidth\n  width: 100%\n  max-width: 700px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"defaultWidth": `l1z11DE27A_Gs6a6kXLw`
};
export default ___CSS_LOADER_EXPORT___;
