// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rzUY7fhhAg3asErHG5B1{font-size:14px !important;text-transform:uppercase;border-radius:0 !important}.rzUY7fhhAg3asErHG5B1:hover{color:#fff}`, "",{"version":3,"sources":["webpack://./src/pages/Assets/pages/WorkList/components/MultipleWorksButtons/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,yBAAA,CACA,wBAAA,CACA,0BAAA,CACA,4BACE,UAAA","sourcesContent":[".button\n  font-size: 14px !important\n  text-transform: uppercase\n  border-radius: 0 !important\n  &:hover\n    color: #fff\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `rzUY7fhhAg3asErHG5B1`
};
export default ___CSS_LOADER_EXPORT___;
