// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xdSF2t9_nYPtl4ai4q6s{display:table;border-collapse:collapse;margin:0;padding:0;width:100%}.PjtGcYn5dLdh4nCv7q7K{list-style:none;display:table-row}.D9XooP5wWfiTT2bH3tl_{list-style:none;display:table-cell}`, "",{"version":3,"sources":["webpack://./src/components/lists/ListAsTable/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,aAAA,CACA,wBAAA,CACA,QAAA,CACA,SAAA,CACA,UAAA,CACF,sBACE,eAAA,CACA,iBAAA,CACF,sBACE,eAAA,CACA,kBAAA","sourcesContent":[".listTable\n  display: table\n  border-collapse: collapse\n  margin: 0\n  padding: 0\n  width: 100%\n.row\n  list-style: none\n  display: table-row\n.cell\n  list-style: none\n  display: table-cell"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listTable": `xdSF2t9_nYPtl4ai4q6s`,
	"row": `PjtGcYn5dLdh4nCv7q7K`,
	"cell": `D9XooP5wWfiTT2bH3tl_`
};
export default ___CSS_LOADER_EXPORT___;
