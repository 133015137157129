// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lViKZWqiCpguCX8Gnat5.t6DsVmkKoFd2ClgPxg7k{padding:0;background:none;color:#000;cursor:pointer;font-size:18px;font-weight:normal}.n317PErrtD8xyvkmNN0Q{font-size:13px}`, "",{"version":3,"sources":["webpack://./src/components/buttons/navigation/ReturnButton/styles.module.sass"],"names":[],"mappings":"AAAA,2CACE,SAAA,CACA,eAAA,CACA,UAAA,CACA,cAAA,CACA,cAAA,CACA,kBAAA,CACF,sBACE,cAAA","sourcesContent":[".button.current\n  padding: 0\n  background: none\n  color: #000\n  cursor: pointer\n  font-size: 18px\n  font-weight: normal\n.label\n  font-size: 13px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `lViKZWqiCpguCX8Gnat5`,
	"current": `t6DsVmkKoFd2ClgPxg7k`,
	"label": `n317PErrtD8xyvkmNN0Q`
};
export default ___CSS_LOADER_EXPORT___;
