// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.x0XkP08y_i8DFsptV_fb{color:#f13636 !important}.ZwOj7Ch2o8FKZMcpqosl{color:#000 !important}`, "",{"version":3,"sources":["webpack://./src/pages/AssetsBatches/pages/AssetsBatch/components/Validations/components/EditDeleteIconButtons/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CACF,sBACE,qBAAA","sourcesContent":[".fontColor\n  color: #f13636 !important\n.editButton\n  color: #000 !important\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fontColor": `x0XkP08y_i8DFsptV_fb`,
	"editButton": `ZwOj7Ch2o8FKZMcpqosl`
};
export default ___CSS_LOADER_EXPORT___;
