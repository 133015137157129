// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ct8N4lQbfGI8FQ_7p5sw{background-color:#f3f4f5;padding:50px;max-width:1000px}`, "",{"version":3,"sources":["webpack://./src/pages/Assets/pages/RegisterAsset/components/RegisterForm/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CACA,YAAA,CACA,gBAAA","sourcesContent":[".form\n  background-color: #f3f4f5\n  padding: 50px\n  max-width: 1000px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `Ct8N4lQbfGI8FQ_7p5sw`
};
export default ___CSS_LOADER_EXPORT___;
